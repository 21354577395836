import { defineStore } from "pinia";

export const useTrainingStore = defineStore('training_video',{
    state:()=>{
        return {
            my_videos:[],
            assosiate_videos:[]
        }
    },
    actions:{
        setMyVideos(videos){
            this.my_videos = videos
        },
        setAssosiateVideos(videos){
            this.assosiate_videos = videos
        },
        addMyVideo(video){
            this.my_videos.unshift(video)
        },
        addAssosiateVideo(video){
            this.assosiate_videos.unshift(video)
        },
        removeMyVideo(id){
            let index = this.my_videos.findIndex(video=>video.id == id)
            this.my_videos.splice(index,1)
        },
        updateVideoDetail(id,data){
            let index = this.my_videos.findIndex(video=>video.id == id)
            this.my_videos[index] = data
        },
        resetTrainingVideoStore(){
            this.my_video=[]
            this.assosiate_videos=[]
        }
    },
    getters:{
       myVideos:(state)=>{
        return state.my_videos
       }
    }
})